import React from "react"
import { UIStoreContext } from "../../stores/UIStore"
import { observer } from "mobx-react-lite"

import styled from "styled-components"
import { Feature } from "./Feature"
import { SubHeaderCenter } from "../shared/type"

export enum Slug {
  PLUGINS = "plugIns",
  ADDPAGES = "additionalPages",
  NAVIGATION = "navigationMenus",
  ANIMS = "animations",
  PAGETRANSIT = "pageTransitions",
  ICONSGRAPHICS = "iconsAndGraphics",
  EMPTY = "empty",
}

type Feature = {
  title: string
  slug: Slug
}

const features: Feature[] = [
  { title: "Plug Ins", slug: Slug.PLUGINS },
  {
    title: "Additional Pages",
    slug: Slug.ADDPAGES,
  },
  { title: "Navigation Menus", slug: Slug.NAVIGATION },
  { title: "Animations", slug: Slug.ANIMS },
  { title: "Page Transitions", slug: Slug.PAGETRANSIT },
  { title: "Custom Icons & Graphics", slug: Slug.ICONSGRAPHICS },
]

export const AdditionalFeatures = observer(() => {
  const { addFeaturesSelected } = React.useContext(UIStoreContext)

  const handleFeatureClick = (featureSlug: Slug) => {
    addFeaturesSelected[featureSlug] = !addFeaturesSelected[featureSlug]
  }

  return (
      <>
        <SubHeaderCenter>Additional Features</SubHeaderCenter>
          <Content>
          {features.map(feature => (
            <Feature
              key={feature.slug}
              title={feature.title}
              slug={feature.slug}
              click={handleFeatureClick}
              active={addFeaturesSelected[feature.slug]}
            />
          ))}

          <Desktop>
            <Feature title="" slug={Slug.EMPTY} click={handleFeatureClick} />
          </Desktop>

          <Desktop>
            <Feature title="" slug={Slug.EMPTY} click={handleFeatureClick} />
          </Desktop>
        </Content>
      </>
  )
})

const Content = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  padding: 25px 25px 50px 25px;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 182px);
    justify-content: center;
  }
`

const Desktop = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: initial;
  }
`