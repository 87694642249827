import * as React from "react"
import styled from "styled-components"
import { Button } from "../../atoms/button"
import { observer } from "mobx-react-lite"
import { UIStoreContext } from "../../stores/UIStore"
import { Slug } from "../_packages/AdditionalFeatures"

export const StartForm = observer(() => {
  const { addFeaturesSelected, packageSelected } = React.useContext(
    UIStoreContext
  )

  const getFeatureList = () => {
    const features = {
      plugIns: "Plug Ins",
      additionalPages: "Additional Pages",
      navigationMenus: "Navigation Menus",
      animations: "Animations",
      pageTransitions: "Page Transitions",
      iconsAndGraphics: "Icons & Graphics",
      empty: Slug.EMPTY,
    }

    const selectedFeatures = Object.keys(addFeaturesSelected).filter(
      key => addFeaturesSelected[key as Slug]
    )

    return selectedFeatures.map(feature => features[feature as Slug])
  }

  const hasFeatures = packageSelected.package.split(" ")[0] === "Web"

  return (
    <Container>
      <Form
        method="POST"
        data-netlify="true"
        name="get-started"
        action="/get-started/success"
      >
        <input type="hidden" name="form-name" value="get-started" />

        <SelectedPackage row={1} col={1}>
          <h2>Service</h2>
          <h3>{packageSelected.package}</h3>
        </SelectedPackage>

        <SelectedPackage row={1} col={2}>
          <h2>Plan</h2>
          <h3>{packageSelected.plan}</h3>
        </SelectedPackage>

        {hasFeatures && (
          <SelectedFeatures>
            <h2>Additional Features</h2>
            <ChipContainer>
              {getFeatureList().map(feature => (
                <Chip key={feature}>{feature}</Chip>
              ))}
            </ChipContainer>
          </SelectedFeatures>
        )}

        <Input
          type="name"
          name="name"
          placeholder="Your name here..."
          aria-label="Your name"
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Your email here..."
          aria-label="Your email address"
          required
        />
        <Input
          type="tel"
          name="phone"
          placeholder="Your phone number here..."
          aria-label="Your phone number"
          required
        />
        <Label id="sub-check">
          <Input type="checkbox" name="subscribe" />
          <span>I would like to subscribe to Who Studio’s mailing list</span>
        </Label>

        <Input type="hidden" name="package" value={packageSelected.package} />
        <Input type="hidden" name="plan" value={packageSelected.plan} />
        <Input
          type="hidden"
          name="additional-features"
          value={getFeatureList().join(", ")}
        />

        <QuestionInput
          name="question"
          placeholder="How can we help you?"
          aria-label="Your Question"
          required
        />

        <FormButton type="submit">send</FormButton>
      </Form>
    </Container>
  )
})

const Container = styled.div`
  display: grid;
  background-color: var(--white);
  padding: 2rem 25px;
  margin-bottom: 1.75rem;

  @media (min-width: 768px) {
    padding: 2rem 75px;
    margin-bottom: 2.75rem;
  }
`

const Chip = styled.span`
  color: var(--brand-green);
  font: 1rem/1rem var(--body-font);
  padding: 0.5rem;
  border: 1px solid var(--brand-green);
  border-radius: 10px;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
  }
`

const ChipContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-flow: row wrap;

  > * {
    margin-right: 1rem;
  }

  @media (min-width: 768px) {
    align-items: center;
  }
`

const Form = styled.form`
  display: grid;
  grid-row-gap: 1rem;

  @media (min-width: 768px) {
    grid-column-gap: 50px;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  #sub-check {
    display: grid;
    grid-template-columns: 25px 1fr;
    justify-self: start;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      margin-left: 2.5px;
      margin-bottom: 1rem;
    }
  }

  #sub-check > span {
    font: 1rem/1rem var(--body-font);
    color: var(--brand-green);
    padding: 0.5rem 1rem;

    @media (min-width: 768px) {
      padding: 0.5rem 2rem;
    }
  }

  #sub-check > input {
    height: 20px;
    width: 20px;
    appearance: none;
    border: 1px solid #34495e;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: var(--white);
    cursor: pointer;

    @media (min-width: 768px) {
      height: 30px;
      width: 30px;
    }
  }

  #sub-check > input:checked {
    background-color: var(--brand-green);
    box-shadow: 0px 0px 2px 1px white inset;
  }

  > * {
    @media (min-width: 768px) {
      /* grid-column: 2; */
    }
  }

  > input,
  textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid var(--brand-green);

    font: 1rem/1em var(--body-font);

    @media (min-width: 768px) {
      padding: 1.2rem;
    }
  }
`

const FormButton = styled(Button)`
  width: 80%;
  padding: 1rem 0;
  @media (min-width: 768px) {
    grid-column: 1/-1;
    margin: 0;
    width: 100%;
    justify-self: start;
  }
`

const Label = styled.label`
  align-self: start;
`

const Input = styled.input``

const QuestionInput = styled.textarea`
  height: 111px;
  border-radius: 5px;

  @media (min-width: 768px) {
    height: 100%;
    grid-column: 1/-1;
    margin-bottom: 2rem;
  }
`

const SelectedFeatures = styled.section`
  width: 100%;
  font: 1rem/1rem var(--display-font);
  > h2 {
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
    }
  }

  @media (min-width: 768px) {
    grid-column: 1/-1;
  }
`

interface ISelectedPackageProps {
  row: number
  col: number
}
const SelectedPackage = styled.section<ISelectedPackageProps>`
  width: 100%;
  font: 1rem/1rem var(--display-font);

  > h2 {
    margin-bottom: 1rem;
  }

  > h3 {
    font: 1rem/1rem var(--display-font);
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    grid-row: ${({ row }) => row};
    grid-column: ${({ col }) => col};
  }
`
