import * as React from "react"
import { Head } from "../components/head"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

import { StartForm } from "../components/_get-started/startForm"
import { Contact } from "../components/_contact-us/contact"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const GetStartedPage = (): JSX.Element => {
  const { coffeeImage } = useStaticQuery(
    graphql`
      query {
        coffeeImage: file(relativePath: { eq: "services/webd.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const coffeeFluid = coffeeImage.childImageSharp.fluid
  return (
    <>
      <SEO title="Contact Us" />
      <Head title={"Contact Us"} />
      <Layout>
        <Container>
          <Title>Let's get started!</Title>
          <DesktopFluidImg
            fluid={coffeeFluid}
            style={{
              backgroundPosition: "30% 0%",
            }}
          />
          <StartForm />
          <Contact />
        </Container>
      </Layout>
    </>
  )
}

const Container = styled.div`
  @media (min-width: 768px) {
    margin-top: 0rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
`

const DesktopFluidImg = styled(BackgroundImage)`
  display: none;

  @media (min-width: 768px) {
    display: initial;
    grid-row: 1/4;
  }
`

const Title = styled.h1`
  font: 400 1.7rem/2.3rem var(--display-font);
  text-align: center;
  padding-top: 3rem;

  @media (min-width: 768px) {
    font: 400 2.8rem/3.2rem var(--display-font);
    margin-bottom: 2.75rem;
  }
`

export default GetStartedPage
