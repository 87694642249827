import * as React from "react"
import styled from "styled-components"
import { useState } from "react"
import { Slug } from "./AdditionalFeatures"

interface Props {
  title: string
  slug: Slug
  active?: boolean
  click: (featureSlug: Slug) => void
}

export const Feature: React.FC<Props> = ({
  title,
  slug,
  click,
  active,
}): JSX.Element => {
  const [selected, setSelected] = useState(active)

  const handleToggle = () => {
    setSelected(!selected)
    click(slug)
  }

  const Outer = styled.div`
    --brand-border: 3px solid
      ${selected ? `var(--brand-green)` : "rgba(0,0,0,0)"};
    border-bottom: var(--brand-border);
  `

  const Content = styled.div`
    display: grid;
    padding: 5px;
    grid-template-rows: 4rem;
    align-items: center;
    text-align: center;
    --grey-border: 2px solid #e5e5e5;
    --border: 2px solid ${selected ? "var(--brand-green)" : "#e5e5e5"};
    border: var(--grey-border);
    border-bottom: var(--border);

    @media (min-width: 768px) {
      grid-template-rows: 6rem;
    }
  `

  return (
    <>
      <Outer>
        <Content onClick={handleToggle}>
          <FeatureTitle>{title}</FeatureTitle>
        </Content>
      </Outer>
    </>
  )
}

const FeatureTitle = styled.h1`
  font: 400 1.3rem/1.3rem var(--display-font);

  @media (min-width: 768px) {
    padding: 0 1.2rem;
  }
`
